.renewal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding-top: 60px;

    .logo {
        width: clamp(12px, 60vw, 420px);
        margin-bottom: 3vw;

        img {
            width: 100%;
        }
    }

    .copy-heading {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: 16vh;
        font-family: 'IBM Plex Mono', monospace;
        font-size: clamp(14px, 4vw, 22px);
        letter-spacing: 2px;
        color: #FFFFFF;
    }
    
    .background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}